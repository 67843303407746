.rapports-select .select-search {
    width: 70%;
}

.content-div {
    border-top: 1px solid lightgrey;
    padding-top: 20px;
}

.content-div h3 {
    text-align: center;
    text-decoration: underline;
}

@media print {
    .deznav, .search-section, .nav-header, .header  {
        display: none;
    }

    .print-content {
        width: 100%;
        height: 100%;
    }

    .print-content .radio-section {
        display: none;
    }

}
