.doc-table {
    border-collapse: collapse;
    width: 100%;
    background-color: #ffffff;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
}

.doc-table th {
    border-bottom: 1px solid #dfe7ef;
    padding: 1rem;
    text-align: left;
    text-transform: uppercase;
}

.doc-table tbody td:first-child {
    font-weight: 700;
    color: #6366F1;
}

.doc-table tbody td {
    padding: 1rem;
    border-bottom: 1px solid #dfe7ef;
    white-space: pre-wrap;
    line-height: 1.5;
}