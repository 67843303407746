// Set standard colors
$darkslateblue: #2d3c7f;
$white: #fff;
$yellowgreen: #84ba02;
$dimgray: #707070;
$black: #000;

// Font family
$font: "Dosis", sans-serif;

@mixin toggle-switch {
  display: none;

  & + label {
    transition: 0.3s background;
    user-select: none;

    cursor: pointer;
    position: relative;
    display: inline-block;
    height: 2em;
    width: 5em;
    background: $dimgray;
    border-radius: 20em;
    vertical-align: middle;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 0%;
    }

    &:before {
      transition: 0.3s right, 0.3s left;
      content: "";
      display: inline-block;
      background: rgba($white, $alpha: 0.9);
      height: 1.5em;
      width: 1.5em;
      margin: 0.25em;
      border-radius: 50%;
      z-index: 200;
    }

    &:after {
      color: $white;
      content: "\f00d";
      font: 800 1.25em "Font Awesome 5 Free";
      top: 50%;
      margin-top: -0.5em;
      // left: 3em;
      left: unset;
      right: 10%;
    }
  }

  &:checked + label {
    background: $darkslateblue;

    &:before {
      // left: 3em;
      margin: 0.25em 0;
      left: unset;
      right: 4%;
    }

    &:after {
      content: "\f00c";
      right: unset;
      left: 0.5em;
    }
  }
}
