.custom-text-input {
    border: 0 !important;
    font-size: 12px !important;
}

.input-group .dropdown-menu {
    min-width: 12rem !important;
    max-height: 180px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.sup-dental button, .inf-dental button {
    color: black !important;
}

.sup-dental button:hover, .inf-dental button:hover {
    color: #ffb800 !important;
}

.close-button {
    margin-left: 95% !important;
}