.default-tab .header-nav .nav-link:focus, .default-tab .header-nav .nav-link:hover, .default-tab .header-nav .nav-link.active {
    color: #495057 !important;
    background-color: #24439D !important;
    border-color: #dee2e6 #dee2e6 #fff #ebeef6 !important;
    border-radius: 0.75rem 0.75rem 0 0 !important;
    color: #fff !important;
}

.default-tab .header-nav .nav-link:focus i, .default-tab .header-nav .nav-link:hover i, .default-tab .header-nav .nav-link.active i {
    color: #495057 !important;
    background-color: #24439D !important;
    border-color: #dee2e6 #dee2e6 #fff #ebeef6 !important;
    border-radius: 0.75rem 0.75rem 0 0 !important;
    color: #fff !important;
}