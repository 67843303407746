.login-form-bx {
    background-image: 
    /* top, transparent red */
    linear-gradient(4deg, rgba(2,0,36,.8) 0%, rgba(9,9,121,.8) 35%, rgba(0,212,255,.8) 100%),
    /* your image */
    url(../../images/images.jpg);
}

.login-form-bx .box-skew1:after {
    content: "";
    background-image: url(../../images/bg-login.jpg);
    background-size: cover;
    background-position: center !important;
    height: 100%;
    position: absolute;
    width: 120%;
    left: -30px;
    top: 0;
    z-index: -1;
    transform: skew(-5deg);
}