// Import global css global variables
@import "src/scss/global-variables.scss";

.circle {
  position: relative;
}
.circle:before,
.circle:after {
  content: "";
  height: 230px;
  width: 230px;
  display: block;
  position: absolute;
  bottom: 0%;
  top: 0;
  right: 0;
  left: 3%;
  border-radius: 50%;
  border: 1px solid $yellowgreen;
}

.circle:before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}
.circle:after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

.start-screen {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

//   .sidebar {
//     flex: 1;
//     background: linear-gradient(
//         to bottom,
//         rgb(45 60 127 / 0.9),
//         rgb(45 60 127 / 0.9)
//       ),
//       url("../../../assets/img/static/get-started-activa.jpg") no-repeat 40%
//         center;
//     background-size: cover;
//     .uk-container {
//       .activa-logo {
//         margin-top: 45px;
//         text-align: center;
//         height: 90px;
//         overflow: hidden;
//         img {
//           height: 100%;
//         }
//       }
//       .side-text {
//         height: 60vh;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         text-align: center;
//         h3 {
//           line-height: 1.5;
//           font-size: 2.8rem;
//           font-weight: 600;
//           color: $white;
//         }
//       }
//     }
//   }

  // sidebar media for max-width 1177px
  @media screen and (max-width: 1120px) {
    .sidebar {
      .uk-container {
        .side-text {
          h3 {
            font-size: 2.5rem;
          }
        }
      }
    }
  }

  // sidebar media for max-width 1042px
  @media screen and (max-width: 1042px) {
    .sidebar {
      .uk-container {
        .side-text {
          h3 {
            font-size: 2.3rem;
          }
        }
      }
    }
  }

  // sidebar media for max-width 985px
  @media screen and (max-width: 985px) {
    .sidebar {
      .uk-container {
        .side-text {
          h3 {
            font-size: 2.1rem;
          }
        }
      }
    }
  }

  // sidebar media for max-width 846px
  @media screen and (max-width: 846px) {
    .sidebar {
      .uk-container {
        .side-text {
          h3 {
            font-size: 1.9rem;
          }
        }
      }
    }
  }

  // sidebar media for max-width 789px
  @media screen and (max-width: 789px) {
    // .sidebar {
    //   flex: 1;
    //   background: linear-gradient(
    //       to bottom,
    //       rgb(45 60 127 / 0.9),
    //       rgb(45 60 127 / 0.9)
    //     ),
    //     url("/assets/img/static/get-started-activa.jpg") no-repeat 40% center;
    //   background-size: cover;
    //   .uk-container {
    //     .activa-logo {
    //       margin-top: 100px;
    //       text-align: center;
    //       height: 90px;
    //       overflow: hidden;
    //       img {
    //         height: 100%;
    //       }
    //     }
    //     .side-text {
    //       // height: 65vh;
    //       display: flex;
    //       flex-direction: column;
    //       justify-content: center;
    //       text-align: center;
    //       h3 {
    //         font-size: 1.68rem;
    //         font-weight: 600;
    //         color: $white;
    //       }
    //     }
    //   }
    // }
  }

  .display-box {
    flex: 3;
    .uk-container {
      height: 100%;
      overflow: hidden;
      overflow-y: auto;

      .control-tabs {
        height: 10vh;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        button {
          background: $darkslateblue;
          color: $white;
          border: 1px solid $darkslateblue;
          font: 600 1.5rem $font;
          border-radius: 6px;
          padding: 0.5rem;
          width: 150px;
          cursor: pointer;
          transition: linear all 0.5s;
        }
        button:hover {
          color: $darkslateblue;
          background: $white;
          box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28); // 0 0 3px 1px #757575, 0 0 10px 4px #75757569;
        }
        button:first-of-type:before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f053";
          margin-right: 0.7rem;
        }

        button:last-of-type {
          background-color: $dimgray;
          &:after {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f3c5";
            margin-left: 0.7rem;
          }
          &:hover {
            color: $white;
            background: $black;
            box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28); // 0 0 3px 1px #757575, 0 0 10px 4px #75757569;
          }
        }
        // button:last-of-type:after {
        //   font-family: "Font Awesome 5 Free";
        //   font-weight: 900;
        //   content: "\f3c5";
        //   margin-left: 0.7rem;
        // }
      }

      .user-state-forms {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .user-state {
          margin-top: 1.5rem;
          margin-bottom: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          .checkbox-container {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            user-select: none;

            /* Hide the browser's default radio button */
            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
            }

            /* Create a custom radio button */
            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 35px;
              width: 35px;
              background-color: $white;
              border: 1px solid $dimgray;
              border-radius: 50%;
            }

            /* When the radio button is checked, add a blue background */
            input:checked ~ .checkmark {
              background-color: $white;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            /* Create the indicator (the dot/circle - hidden when not checked) */
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }

            /* Show the indicator (dot/circle) when checked */
            input:checked ~ .checkmark:after {
              display: block;
            }

            /* Style the indicator (dot/circle) */
            .checkmark:after {
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background: $darkslateblue;
            }
          }
          .checkbox-container:hover {
            input ~ .checkmark {
              background-color: $darkslateblue;
            }
          }
          label {
            display: inline-block;
            margin-left: 1rem;
            font: 600 2.8rem $font;
            cursor: pointer;
            transition: all linear 0.7s;
            span {
              display: block;
              font: 400 1.15rem $font;
            }
          }
        }
      }

      .entry-form {
        height: unset;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 50px;
        form {
          text-align: center;
          div.fix-form {
            height: 80vh;
          }
          .guide {
            text-align: left;
            margin-bottom: 2rem;
            label {
              font: 600 1.8rem $font;
              color: $dimgray;
              display: inline-block;
            }
          }
          .reg-container {
            margin-bottom: 3rem;
            .the-form {
              .use-facial-reg {
                display: flex;
                align-items: center;
                border-radius: 5px;
                transition: 0.3s box-shadow, 0.3s padding;
                input[type="checkbox"] {
                  @include toggle-switch;
                }

                input + label {
                  margin-right: 20px;
                }
                span {
                  font-size: 1.3rem;
                  font-weight: 500;
                  text-align: left;
                }

                // &:hover {
                //     // padding: 0.5rem;
                //     box-shadow: 0 0 5px 1px rgba($black, $alpha: 0.2);
                // }
              }
            }
            .the-facial {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              .face-box {
                width: 250px;
                height: 250px;
                margin: 1rem;
                img.face-holder {
                  object-fit: contain;
                }
                .face-holder-box {
                  height: 230px;
                  width: 230px;
                  border-radius: 100%;
                  border: 2px solid $yellowgreen;
                  overflow: hidden;
                  img {
                    object-fit: cover;
                    object-position: center;
                    height: 100%;
                  }
                }
                webcam {
                  .webcam-wrapper {
                    height: 230px;
                    width: 230px;
                    border-radius: 100%;
                    border: 2px solid $yellowgreen;
                    overflow: hidden;
                    video {
                      object-fit: cover;
                      height: 100%;
                    }
                  }
                }
                .take-shot {
                  border: none;
                  background: none;
                  display: inline-block;
                  margin-top: 0.8rem;
                  cursor: pointer;
                  font: 600 1rem $font;
                  position: absolute;
                  top: 3%;
                  right: 3%;
                  transition: all ease-in-out 0.5s;
                }
                .take-shot::before {
                  margin-right: 0.3rem;
                  content: "\f030";
                  font: 900 1.3rem "Font Awesome 5 Free";
                  color: $darkslateblue;
                  transition: all ease-in-out 0.5s;
                }
                .take-shot:hover {
                  color: $yellowgreen;
                }
                .take-shot:hover::before {
                  color: $yellowgreen;
                }

                .switch-cam {
                  display: inline-block;
                  margin-top: 1rem;
                  font: 600 1rem $font;
                  cursor: pointer;
                  color: $darkslateblue;
                  transition: all ease-in-out 0.5s;
                }
                .switch-cam::before {
                  content: "\f2f1";
                  font: 900 1rem "Font Awesome 5 Free";
                  margin-right: 0.5rem;
                  transition: all ease-in-out 0.5s;
                  color: inherit;
                  // color: $darkslateblue;
                }
                .switch-cam:hover {
                  color: $yellowgreen;
                }
              }
              &::before {
                content: "";
                width: 100%;
                height: 115%;
                border-radius: 5px;
                display: block;
                position: absolute;
                z-index: 2000;
                backdrop-filter: blur(10px);
                background: rgba($darkslateblue, $alpha: 0.5);
                cursor: not-allowed;
                opacity: 0;
                pointer-events: none;
                transition: all 0.5s linear;
              }
              &.no-facial::before {
                opacity: 1;
                pointer-events: visible;
              }
            }
          }
          .login-container {
            .the-form {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            .the-facial {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              .face-box {
                width: 250px;
                height: 250px;
                margin: 1rem;
                img.face-holder {
                  object-fit: contain;
                }
                .face-holder-box {
                  height: 230px;
                  width: 230px;
                  border-radius: 100%;
                  border: 2px solid $yellowgreen;
                  overflow: hidden;
                  img {
                    object-fit: cover;
                    object-position: center;
                    height: 100%;
                  }
                }
                webcam {
                  .webcam-wrapper {
                    height: 230px;
                    width: 230px;
                    border-radius: 100%;
                    border: 2px solid $yellowgreen;
                    overflow: hidden;
                    video {
                      object-fit: cover;
                      height: 100%;
                    }
                  }
                }
                .take-shot {
                  border: none;
                  background: none;
                  display: inline-block;
                  margin-top: 0.8rem;
                  cursor: pointer;
                  font: 600 1rem $font;
                  position: absolute;
                  top: 3%;
                  right: 3%;
                  transition: all ease-in-out 0.5s;
                }
                .take-shot::before {
                  margin-right: 0.3rem;
                  content: "\f030";
                  font: 900 1.3rem "Font Awesome 5 Free";
                  color: $darkslateblue;
                  transition: all ease-in-out 0.5s;
                }
                .take-shot:hover {
                  color: $yellowgreen;
                }
                .take-shot:hover::before {
                  color: $yellowgreen;
                }

                .switch-cam {
                  display: inline-block;
                  margin-top: 1rem;
                  font: 600 1rem $font;
                  cursor: pointer;
                  color: $darkslateblue;
                  transition: all ease-in-out 0.5s;
                }
                .switch-cam::before {
                  content: "\f2f1";
                  font: 900 1rem "Font Awesome 5 Free";
                  margin-right: 0.5rem;
                  transition: all ease-in-out 0.5s;
                  color: inherit;
                  // color: $darkslateblue;
                }
                .switch-cam:hover {
                  color: $yellowgreen;
                }
                .reset-shot {
                  border: none;
                  background: none;
                  display: inline-block;
                  margin-top: 0.8rem;
                  cursor: pointer;
                  font: 600 1rem $font;
                  position: absolute;
                  top: 3%;
                  right: 0%;
                  transition: all ease-in-out 0.5s;
                }
                .reset-shot::before {
                  margin-right: 0.3rem;
                  content: "\f2f9";
                  font: 900 1.3rem "Font Awesome 5 Free";
                  color: #a71c12;
                  transition: all ease-in-out 0.5s;
                }
              }
              .divider {
                position: absolute;
                left: 0%;
                background: $darkslateblue;
                color: $white;
                font: 700 1.2rem $font;
                border-radius: 100%;
                padding: 0.5rem;
              }
              .divider::before {
                content: "";
                background: $darkslateblue;
                content: "";
                height: 280px;
                width: 2px;
                position: absolute;
                bottom: -280%;
                left: 49%;
              }
            }
          }
          .uk-inline {
            margin-bottom: 1rem;
            cursor: text;
            input {
              background: #f2f4ff;
              border-radius: 6px;
              height: 70px;
              font: 600 2rem $font;
              padding-left: 20px;
              padding-right: 20px;
              transition: all linear 0.7s;
            }
            input::placeholder {
              font: 600 2rem $font;
              color: $dimgray;
            }
            input:focus,
            input:hover {
              border: 1px solid $darkslateblue;
              box-shadow: 0 0px 10px rgba(32, 33, 36, 0.28);
            }
            .uk-form-icon {
              justify-content: left;
              color: $darkslateblue;
            }
            .uk-form-icon.email {
              font-size: 1.3rem;
              font-weight: 800;
            }
          }

          button[type="submit"] {
            margin-bottom: 2.5rem;
            font-size: 2rem;
            font-weight: 600;
            padding: 0.5rem;
            background: $darkslateblue;
            border: 1px solid $darkslateblue;
            border-radius: 6px;
            color: $white;
            cursor: pointer;
            transition: ease-in-out all 1s;
          }
          button[type="submit"]:after {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f054";
            margin-left: 0.7rem;
          }
          button[type="submit"]:hover {
            color: $darkslateblue;
            background: $white;
            box-shadow: 0 0 3px 1px #757575, 0 0 10px 4px #75757569;
          }
        }
      }

      .poweredby {
        pointer-events: none;
        margin-top: 2rem;
        margin-bottom: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          color: $black;
          font: 500 0.9rem $font;
        }
        img {
          height: 1.7rem;
        }
      }
    }
  }

  // .display-box media for max-width 1066px
  @media screen and (max-width: 1066px) {
    .display-box {
      .uk-container {
        .user-state-forms {
          .user-state {
            label {
              font: 600 2rem $font;
              span {
                font: 400 1.15rem $font;
              }
            }
          }
        }
      }
    }
  }

  //.display-box media for max-width 1020px
  @media screen and (max-width: 1020px) {
    .display-box {
      .uk-container {
        .entry-form {
          form {
            margin-top: 2rem;
            .reg-container {
              .the-form {
                width: 100%;
              }
              .the-facial {
                width: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                .face-box {
                  width: 250px;
                  height: 250px;
                  margin: 1rem;
                  img.face-holder {
                    object-fit: contain;
                  }
                  .face-holder-box {
                    height: 230px;
                    width: 230px;
                    border-radius: 100%;
                    border: 2px solid $yellowgreen;
                    overflow: hidden;
                    img {
                      object-fit: cover;
                      object-position: center;
                      height: 100%;
                    }
                  }
                  webcam {
                    .webcam-wrapper {
                      height: 230px;
                      width: 230px;
                      border-radius: 100%;
                      border: 2px solid $yellowgreen;
                      overflow: hidden;
                      video {
                        object-fit: cover;
                        height: 100%;
                      }
                    }
                  }
                  .take-shot {
                    border: none;
                    background: none;
                    display: inline-block;
                    margin-top: 0.8rem;
                    cursor: pointer;
                    font: 600 1rem $font;
                    position: absolute;
                    top: 3%;
                    right: 3%;
                    transition: all ease-in-out 0.5s;
                  }
                  .take-shot::before {
                    margin-right: 0.3rem;
                    content: "\f030";
                    font: 900 1.3rem "Font Awesome 5 Free";
                    color: $darkslateblue;
                    transition: all ease-in-out 0.5s;
                  }
                  .take-shot:hover {
                    color: $yellowgreen;
                  }
                  .take-shot:hover::before {
                    color: $yellowgreen;
                  }
                }
              }
            }
            .login-container {
              .the-form {
                width: 100%;
              }
              .the-facial {
                width: 100%;
                padding-top: 40px;
                .divider {
                  position: absolute;
                  top: 0%;
                  left: 50%;
                  background: $darkslateblue;
                  color: $white;
                  font: 700 1.2rem $font;
                  border-radius: 100%;
                  padding: 0.5rem;
                }
                .divider::before {
                  content: "";
                  background: $darkslateblue;
                  content: "";
                  height: 2px;
                  width: 100px;
                  position: absolute;
                  bottom: 45%;
                  right: 100%;
                  left: unset;
                }
                .divider::after {
                  content: "";
                  background: $darkslateblue;
                  content: "";
                  height: 2px;
                  width: 100px;
                  position: absolute;
                  bottom: 45%;
                  left: 100%;
                  right: unset;
                }
                webcam {
                  .webcam-wrapper {
                    height: 230px;
                    width: 230px;
                    border-radius: 100%;
                    border: 2px solid $yellowgreen;
                    overflow: hidden;
                    video {
                      object-fit: cover;
                      height: 100%;
                    }
                  }
                }
              }
            }
            button[type="submit"] {
              width: 75%;
            }
          }
        }
      }
    }
  }

  // .display-box media for max-width 1000px
  @media screen and (max-width: 1000px) {
    .display-box {
      .uk-container {
        .entry-form {
          form {
            width: 80%;
          }
        }
      }
    }
  }

  // .display-box media for max-width 930px
  @media screen and (max-width: 930px) {
    .display-box {
      .uk-container {
        .entry-form {
          form {
            width: 100%;
          }
        }
      }
    }
  }

  // .display-box media for max-width 880px
  @media screen and (max-width: 880px) {
    .display-box {
      flex: 3;
      .uk-container {
        overflow: hidden;
        overflow-y: auto;
        .entry-form {
          form {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}

// Apple iPad Pro 12.9 (2020) Portrait Media Queries
@media only screen and (max-width: 1024px) and (orientation: portrait) {
  /* Your Styles... */
  .start-screen {
    flex-direction: column-reverse;
    .sidebar {
      // background: linear-gradient(to bottom, rgb(45 60 127 / 0.9), rgb(45 60 127 / 0.9)), url("/assets/img/static/get-started-activa.jpg") no-repeat 40% 35%;
      background-size: cover;
      flex: unset;
      .uk-container {
        height: 28vh;
        display: flex;
        flex-direction: flex;
        justify-content: center;
        align-items: center;
        .activa-logo {
          margin-top: 20px;
          height: 90px;
          margin-bottom: 1rem;
          img {
            height: 100%;
            object-fit: contain;
          }
        }
        .side-text {
          height: unset;
          text-align: right;
          padding-left: 10px;
          h3 {
            font-size: 1.5rem;
            margin-bottom: 0;
          }
        }
      }
    }
    .display-box {
      .uk-container {
        height: 72vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: auto;
        .control-tabs {
          margin-top: 20px;
          margin-bottom: 30px;
          height: 15vh;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
        .entry-form {
          padding-top: 0px;
          margin-bottom: 20px;
          height: 90vh;
          margin-top: unset;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .uk-width-3-4 {
            width: 95%;
            form {
              div.fix-form {
                height: 50vh;
              }
              button[type="submit"] {
                margin-top: 0rem;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}

// .start-screen media for max-width 750px
@media screen and (max-width: 750px) {
  .start-screen {
    flex-direction: column-reverse;
    .sidebar {
      // background: linear-gradient(to bottom, rgb(45 60 127 / 0.9), rgb(45 60 127 / 0.9)), url("/assets/img/static/get-started-activa.jpg") no-repeat 40% 35%;
      background-size: cover;
      flex: unset;
      .uk-container {
        height: 28vh;
        display: flex;
        flex-direction: flex;
        justify-content: center;
        align-items: center;
        .activa-logo {
          margin-top: 20px;
          height: 90px;
          margin-bottom: 1rem;
          img {
            height: 100%;
            object-fit: contain;
          }
        }
        .side-text {
          height: unset;
          text-align: right;
          padding-left: 10px;
          h3 {
            font-size: 1.5rem;
            margin-bottom: 0;
          }
        }
      }
    }
    .display-box {
      .uk-container {
        height: 72vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: auto;
        .control-tabs {
          margin-top: 20px;
          margin-bottom: 30px;
          height: 15vh;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
        .entry-form {
          margin-bottom: 20px;
          height: fit-content; // 100vh;
          margin-top: unset;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;

          form {
            div.fix-form {
              height: 50vh;
            }
            button[type="submit"] {
              margin-top: unset;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

// .start-screen media for max-width 590px
@media screen and (max-width: 590px) {
  .start-screen {
    .display-box {
      .uk-container {
        .user-state-forms {
          display: flex;
          flex-flow: column;
          justify-content: space-around;
          align-items: center;
          .user-state {
            width: 100%;
            justify-content: flex-start;
            label {
              font: 600 2rem $font;
              span {
                font: 400 1rem $font;
              }
            }
          }
          .user-state:first-of-type {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

// .start-screen media for max-width 540px
@media screen and (max-width: 540px) {
  .start-screen {
    // flex-direction: column;
    .sidebar {
      // background: linear-gradient(to bottom, rgb(45 60 127 / 0.9), rgb(45 60 127 / 0.9)), url("/assets/img/static/get-started-activa.jpg") no-repeat 40% 35%;
      background-size: cover;
    }
    .display-box {
      .uk-container {
        .control-tabs {
          button {
            width: 135px;
          }
        }
        .entry-form {
          form {
            text-align: center;
            div.fix-form {
              height: 50vh;
            }
            .uk-inline {
              margin-bottom: 1rem;
              input {
                background: #f2f4ff;
                border-radius: 6px;
                height: 70px;
                font: 600 1.3rem $font;
                padding-left: 20px;
                padding-right: 20px;
                transition: all linear 0.7s;
              }
              input::placeholder {
                font: 600 1.3rem $font;
                color: $dimgray;
              }
              .uk-form-icon {
                justify-content: left;
                color: $darkslateblue;
              }
              .uk-form-icon.email {
                font-size: 1.3rem;
                font-weight: 800;
              }
            }

            button[type="submit"] {
              width: 80%;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}

// .start-screen media for max-width 520px
@media screen and (max-width: 520px) {
  .start-screen {
    .display-box {
      .uk-container {
        .entry-form {
          form {
            .reg-container {
              // .the-form {}
              .the-facial {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                .face-box {
                  width: 250px;
                  height: 250px;
                  margin: 1rem;
                  img.face-holder {
                    object-fit: contain;
                  }
                  webcam {
                    .webcam-wrapper {
                      height: 200px;
                      width: 200px;
                      border-radius: 100%;
                      border: 2px solid $yellowgreen;
                      overflow: hidden;
                      video {
                        object-fit: cover;
                        height: 100%;
                      }
                    }
                  }
                }
              }
            }
            .login-container {
              // .the-form {}
              .the-facial {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                .face-box {
                  width: 250px;
                  height: 250px;
                  margin: 1rem;
                  img.face-holder {
                    object-fit: cover;
                    object-position: center;
                  }
                  webcam {
                    .webcam-wrapper {
                      height: 200px;
                      width: 200px;
                      border-radius: 100%;
                      border: 2px solid $yellowgreen;
                      overflow: hidden;
                      video {
                        object-fit: cover;
                        height: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .circle:before,
  .circle:after {
    content: "";
    height: 200px;
    width: 200px;
    display: block;
    position: absolute;
    bottom: 0%;
    top: 0;
    right: 0;
    left: 10%;
    border-radius: 50%;
    border: 1px solid $yellowgreen;
  }
}

@media screen and (max-width: 414px) {
  .circle:before,
  .circle:after {
    content: "";
    height: 200px;
    width: 200px;
    display: block;
    position: absolute;
    bottom: 0%;
    top: 0;
    right: 0;
    left: 9%;
    border-radius: 50%;
    border: 1px solid $yellowgreen;
  }
}

@media screen and (max-width: 384px) {
  .circle:before,
  .circle:after {
    content: "";
    height: 200px;
    width: 200px;
    display: block;
    position: absolute;
    bottom: 0%;
    top: 0;
    right: 0;
    left: 9%;
    border-radius: 50%;
    border: 1px solid $yellowgreen;
  }
}

@media screen and (max-width: 375px) {
  .circle:before,
  .circle:after {
    content: "";
    height: 200px;
    width: 200px;
    display: block;
    position: absolute;
    bottom: 0%;
    top: 0;
    right: 0;
    left: 10%;
    border-radius: 50%;
    border: 1px solid $yellowgreen;
  }
}

@media screen and (max-width: 360px) {
  .circle:before,
  .circle:after {
    content: "";
    height: 200px;
    width: 200px;
    display: block;
    position: absolute;
    bottom: 0%;
    top: 0;
    right: 0;
    left: 9%;
    border-radius: 50%;
    border: 1px solid $yellowgreen;
  }
}

@media screen and (max-width: 320px) {
  .circle:before,
  .circle:after {
    content: "";
    height: 200px;
    width: 200px;
    display: block;
    position: absolute;
    bottom: 0%;
    top: 0;
    right: 0;
    left: 8%;
    border-radius: 50%;
    border: 1px solid $yellowgreen;
  }
}

@media screen and (max-width: 280px) {
  .circle:before,
  .circle:after {
    content: "";
    height: 200px;
    width: 200px;
    display: block;
    position: absolute;
    bottom: 0%;
    top: 0;
    right: 0;
    left: 0%;
    border-radius: 50%;
    border: 1px solid $yellowgreen;
  }
}

.pointer-none {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
