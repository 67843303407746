.results-section {
    margin: 20px;
}

.results-section h3 {
    margin-bottom: 25px;
    text-decoration: underline;
}

.results-section ul li {
    display: flex;
    margin: 10px 0;
    gap: 10px;
    color: #24439D;
}

.results-section ul li h4 {
    color: #24439D;
}