.custom-chatbox .img_cont_msg {
    width: 30px;
    height: 30px;
    display: block;
    max-width: 30px;
    min-width: 30px; }
    .custom-chatbox .img_cont_msg img {
      width: 100%; }
  .custom-chatbox .msg_cotainer {
    background: #24439D;
    margin-left: 10px;
    border-radius: 0 0.75rem 0.75rem 0.75rem;
    padding: 10px 15px;
    color: #fff;
    position: relative; }
    .custom-chatbox .msg_cotainer .msg_time {
      display: block;
      font-size: 11px;
      color: #fff;
      margin-top: 5px;
      opacity: 0.5; }
    .custom-chatbox .msg_cotainer:after {
      content: "";
      position: absolute;
      left: -10px;
      border-right: 10px solid #24439D;
      border-bottom: 10px solid transparent;
      border-top: 0px solid;
      top: 0; }
  .custom-chatbox .msg_cotainer_send {
    background: #F6FBF8;
    padding: 10px 15px;
    border-radius: 6px 0px 6px 6px;
    margin-right: 10px;
    color: #222;
    position: relative;
    text-align: right; }
    [data-theme-version="dark"] .custom-chatbox .msg_cotainer_send {
      background: #181f39;
      color: #fff; }
    .custom-chatbox .msg_cotainer_send .msg_time_send {
      display: block;
      font-size: 11px;
      text-align: right;
      margin-top: 5px;
      opacity: 0.6; }
    .custom-chatbox .msg_cotainer_send:after {
      content: "";
      position: absolute;
      right: -10px;
      border-left: 10px solid #F6FBF8;
      border-bottom: 10px solid transparent;
      border-top: 0px solid;
      top: 0; }
      [data-theme-version="dark"] .custom-chatbox .msg_cotainer_send:after {
        border-left: 10px solid #181f39; }
  .custom-chatbox .type_msg .form-control {
    padding: 10px 0;
    height: 50px;
    border: 0; }
  .custom-chatbox .type_msg .btn {
    font-size: 18px;
    border-radius: 38px !important;
    width: 38px;
    height: 38px;
    padding: 0;
    margin-top: 6px; }